/*////////////////////////////////////////////////////////////   HEADER   /////////////*/
.maincontent{
	padding: 80px 15px;
	@include bp(sm){
		padding: 40px 15px 30px;
	}
	img{
		display: inline;
	}
	figure{
		text-align: center;
		margin: 40px 0;
	}
	li{
		padding-left: 20px;
		position: relative;
		margin: 10px 0;
		&:before{
			content: $icon-arrow-right;
			display: inline-block;
			position: absolute;
			left: 0;
			color: $primary;
			font-family: icomoon;
		}
	}
	
}

.avantage{
	background: $lightblue;
	padding: 80px 8% 30px;
	color: $textOn-lightblue;
	@include bp(sm){
		padding: 40px 8% 20px;
	}
	.row{
		margin-right: -60px;
		margin-left: -60px;
		@include bp(md){
			margin-left: 0px;
			margin-right: 0px;
		}
	}
	.title{
		color: $primary;
		font-weight: 700;
		font-size: 19px;
		&:before{
			content: $icon-check-circle;
			font-family: icomoon;
			display: inline-block;
			margin-right: 13px;
			font-weight: normal;
			font-size: 24px;
		}
	}
	.single{
		padding: 0 60px;
		margin-bottom: 50px;
		@include bp(md){
			padding: 0 30px;
		}
		@include bp(sm){
			padding: 0;
			margin-bottom: 10px;
		}
	}
}