//------------------------- FONTS

//import & setup

@font-face {
  font-family: Catamaran;
  src: url(./../fonts/Catamaran-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Catamaran;
  src: url(./../fonts/Catamaran-Light.ttf);
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Catamaran;
  src: url(./../fonts/Catamaran-ExtraLight.ttf);
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: Catamaran;
  src: url(./../fonts/Catamaran-Thin.ttf);
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: Catamaran;
  src: url(./../fonts/Catamaran-Medium.ttf);
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Catamaran;
  src: url(./../fonts/Catamaran-SemiBold.ttf);
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: Catamaran;
  src: url(./../fonts/Catamaran-Bold.ttf);
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Catamaran;
  src: url(./../fonts/Catamaran-ExtraBold.ttf);
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: Catamaran;
  src: url(./../fonts/Catamaran-Black.ttf);
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: Angelina;
  src: url(./../fonts/angelina.ttf);
  font-weight: 400;
  font-style: normal;
}


//icomoon

$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "./../fonts/" !default;

$icon-agricole: "\e908";
$icon-alimentaire: "\e909";
$icon-carriere: "\e90a";
$icon-ciment: "\e90b";
$icon-enrobe: "\e90c";
$icon-home: "\e90d";
$icon-industrie: "\e90e";
$icon-arrow-down: "\e900";
$icon-arrow-left: "\e901";
$icon-arrow-right: "\e902";
$icon-arrow-up: "\e903";
$icon-check-circle: "\e904";
$icon-mail: "\e905";
$icon-map-pin: "\e906";
$icon-phone: "\e907";


@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?c7shc9');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?c7shc9#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?c7shc9') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?c7shc9') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?c7shc9##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-agricole {
  &:before {
    content: $icon-agricole; 
  }
}
.icon-alimentaire {
  &:before {
    content: $icon-alimentaire; 
  }
}
.icon-carriere {
  &:before {
    content: $icon-carriere; 
  }
}
.icon-ciment {
  &:before {
    content: $icon-ciment; 
  }
}
.icon-enrobe {
  &:before {
    content: $icon-enrobe; 
  }
}
.icon-home {
  &:before {
    content: $icon-home; 
  }
}
.icon-industrie {
  &:before {
    content: $icon-industrie; 
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up; 
  }
}
.icon-check-circle {
  &:before {
    content: $icon-check-circle; 
  }
}
.icon-mail {
  &:before {
    content: $icon-mail; 
  }
}
.icon-map-pin {
  &:before {
    content: $icon-map-pin; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}


/*//////////////////////////////////////////////////   override & variables   /////////////*/
//------------------------- COLOR

$primary: #2849aa;
$lightblue: #ecf4f9;
$darkblue: #0a1d53;
$font-text-color: #a6a8b0;
$textOn-lightblue: #686b7b;

//------------------------- TITLE



//------------------------- BTN

$btn-padding-y: .6rem;
$btn-padding-x: 2.1rem;
$btn-border-radius: 0;

//------------------------- FORM

$input-padding-y: .65rem;
$input-padding-x: 1.2rem;

//------------------------- BREAKPOINT

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1000px,
  xl: 1100px,
  xxl: 1440px,
);

@mixin bp($point) {


@if $point == sm{
  @media (max-width: 576px){ @content ; }
}

@if $point == md {
  @media (max-width: 768px){ @content ; }
}

@if $point == lg {
  @media (max-width: 992px){ @content ; }
}

@if $point == xl {
  @media (max-width: 1280px) { @content ; }
}

@else if $point == xxl {
  @media (max-width: 1440px){ @content ; }
}
}



//------------------------- EXTEND / MIXIN