/*////////////////////////////////////////////////////////////   GLOBAL   /////////////*/

.footer{
	@include bp(md){
		flex-direction: column;
	}
}
.foot-logo{
	width: 29%;
	text-align: center;
	@include bp(lg){
		padding: 30px;
	}
	@include bp(sm){
		width: 80%;
	}
}
.foot-content{
	background: $primary;
	color: white;
	width: 71%;
	padding: 70px 80px 50px;
	@include bp(lg){
		padding: 50px 60px 40px;
	}
	@include bp(md){
		width: 100%;
	}
	@include bp(sm){
		padding: 30px 15px;
	}
	a{
		color: white;
	}
	&>div:first-child{
		margin-bottom: 60px;
	}
	ul{
		text-align: right;
	}
	&-contact{
		a{
			display: block;
			margin-top: 10px;
			&:before{
				content: $icon-phone;
				display: inline-block;
				color: #7e99e9;
				margin-right: 15px;
				font-family: icomoon;
				@include bp(sm){
					margin-right: 10px;
				}
			}
		}
		.mail{
			text-decoration: underline;
			&:before{
				content: $icon-mail;
			}
		}
		.title{
			@include bp(sm){
				font-size: 17px;
			}
		}
	}
	.menu{
		a{
			font-weight: 600;
			transition: all .4s ease-out;
			&:hover{
				text-decoration: underline;
			}
		}
		li{
			margin-bottom: 10px;
		}
		.dropdown-toggle{
			&:after{
				display:none;
			}
		}
		.dropdown-menu{
			border: 0;
			border-radius: 0;
			transition: all .2s ease-out;
			a{
				color: #686b7b;
				&:hover{
					text-decoration:none;
				}
			}
		}
		.dropdown-item:hover{
			background: $lightblue;
		}
	}
	.credit{
		font-size: 13px;
		color: #7e99e9;
		@include bp(lg){
			flex-direction: column;
		}
		a{
			color: #7e99e9;
			&:hover{
				color: white;
			}
		}
	}
}