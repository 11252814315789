/*////////////////////////////////////////////////////////////   GLOBAL   /////////////*/
.topbar{
	position: absolute;
	padding: 60px 8%;
	width: 100%;
	display: flex;
	justify-content: space-between;
	@include bp(sm){
		padding: 30px;
	}
	a{
		color: white;
	}
	.top_logo{
		@include bp(lg){
			width: 70%;
		}
		@include bp(sm){
			width: 50%;
		}
	}
	.menu{
		display: flex;
		@include bp(lg){
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 100%;
		}
		li:not(:first-child){
			margin-left: 60px;
			@include bp(lg){
				margin: 0 0 30px 0;
			}
			@include bp(sm){
				margin: 0 0 15px 0;
			}
		}
		li{
			transition: all .4s ease-out;
			@include bp(lg){
				margin-bottom: 30px;
				font-size: 24px;
				opacity: 0;
			}
			@include bp(sm){
				font-size: 20px;
				margin-bottom: 15px;
			}
			&.launch{
				opacity: 1;
			}
			&:after{
				content: '';
				background: white;
				display: block;
				width: 0;
				height: 2px;
				margin: 5px auto;
				opacity: 0;
				transition: all .3s ease-in-out;
				@include bp(sm){
					margin: 3px auto;
				}
			}
			&:hover{
				&:after{
					opacity: .5;
					width: 40%;
				}
			}
		}
		.active{
			&:after{
				opacity: 1;
				width: 40%;
			}
		}
		.dropdown-toggle{
			&:after{
				display:none;
			}
		}
		.dropdown-menu{
			border: 0;
			border-radius: 0;
			transition: all .2s ease-out;
			a{
				color: #686b7b;
			}
		}
		.dropdown-item:hover{
			background: $lightblue;
		}
	}
	.navtop{
		@include bp(lg){
			position: absolute;
			background: $darkblue;
			width: 100%;
			height: 0;
			top: 0;
			left: 0;
			z-index: 99;
			transition: all .7s ease-out;
		}
	}
	.animate{
		height: 100vh;
	}
}

/* ///////////////////  Nav Burger  ////////////////////////*/

.burger-wrap{
    position: relative;
    z-index: 1110;
}
.burger {
    height: 17px; 
    width: 26px;
    cursor: pointer;    
    z-index: 1110;
    transition: all 0.2s ease-in-out;
    .x, .y, .z {
        height: 2px; width: 26px;
        position: relative;
        background: white;
        border-radius:2px;
        transition: all 200ms ease-out;
    }   
    .y{top:5px;}
    .z{top:10px;}
    &:hover, &.open{
        .x, .y, .z{
            background: white;
        }
    }
}
.rotate30{
    transform: rotate(45deg);
    transform-origin: 4px;
}
.rotateneg{
    transform: rotate(-45deg);
    transform-origin: 5px;
}


/*///////////////////////////////////////////////////   HEADBANNER INNER   /////////////*/

header.inner{
	position: relative;
	.contain{
		&.service-analyse{ background: url(../images/headbanner-analyse.jpg) center no-repeat; background-size: cover; }
		&.head-contact{ background: url(../images/headbanner-contact.jpg) center no-repeat; background-size: cover; }
		&.head-galerie{ background: url(../images/headbanner-galerie.jpg) center no-repeat; background-size: cover; }
		height: 325px;
		overflow: hidden;
		margin: 0;
		@include bp(sm){
			height: 250px;
		}
	}

}
.content_header.inner{
	.title{
		animation-delay: .4s;
	}
}
.ariane{
	background: $lightblue;
	padding: 15px 8% 12px;
	ul{
		display: flex;
	}
	li{
		line-height: 1;
		font-size: .85rem;
		font-weight: 500;
		color: #686b7b;
		margin: 0 5px 0 0;
		a{
			color: #686b7b;
			&:hover{
				color: $darkblue;
			}
		}
		&:after{
			content: $icon-arrow-right;
			display: inline-block;
			color: $darkblue;
			font-family: icomoon;
			font-size: 12px;
			opacity: 0;
		}
		&:not(:last-child):after{
			opacity: 1;
		}
		&:first-child{
			a{
				color: $primary;
			}
		}
	}
}