/*////////////////////////////////////////////////////////////   GLOBAL   /////////////*/
body{
	font-family: Catamaran;
	color: $font-text-color;
	
	@include bp(md){
		font-size: .9rem;
	}
	ul{
		margin-bottom: 0;
		padding: 0;
		li{
			list-style-type: none;
		}
	}
	img{
		max-width: 100%;
	}
	a{
		&:hover{
			text-decoration: none;
		}
	}
}

.intro{
	text-align: center;
}
.subtitle{
	color: #b9bdcd;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-weight: 800;
	font-size: 12px;
}
.maintitle{
	font-weight: 700;
	font-size: 40px;
	margin-bottom: 30px;
	color: $darkblue;
	@include bp(sm){
		font-size: 28px;
		margin-bottom: 15px;
	}
}

.paraphe{
	font-size: 19px;
	color: #686b7b;
	padding: 50px 20%;
	border-bottom: 1px solid #e1e5f3;
	text-align: center;
	@include bp(lg){
		padding: 50px 10%;
	}
	@include bp(sm){
		padding: 30px 10%;
		font-size: 16px;
	}
}
.lead{
	font-size: inherit;
	color: $primary;
	font-weight: 600;
}