/*/////////////////////////////////////////////////   engagement qualité   /////////////*/
.full_background{
	padding: 85px 20%;
	text-align: center;
	position: relative;
	background: url('../images/background_about.jpg') no-repeat;
	background-attachment: fixed;
	background-size: cover;
	color: #c3c4c9;
	@include bp(xl){
		padding: 85px 15%;
	}
	@include bp(md){
		padding: 60px 8%;
	}
	@include bp(sm){
		padding: 40px 8% 30px;
	}
	.lead{
		color: white;
	}
	.maintitle{
		color: white;
	}
	.quote{
		color: white;
		font-size: 25px;
		font-weight: 300;
		padding: 0 100px;
		@include bp(lg){
			padding: 0;
		}
		@include bp(md){
			font-size: 20px;
		}
	}
}
.certification_list{
	display: flex;
	justify-content: center;
	margin: 40px 0;
	li{
		margin: 20px;
	}
}

/*//////////////////////////////////////////////////   Intervention   /////////////*/
.intervention .row{
	margin:0;
}
.perimetre{
	background: $lightblue;
	padding: 60px 80px;
	@include bp(sm){
		padding: 40px 8% 30px;
	}
	figure{
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	li{
		margin: 5px;
		img{
			margin-right: 15px;
		}
	}
}
.domaine{
	padding: 60px 110px;
	@include bp(xxl){
		padding: 60px 90px;
	}
	@include bp(xl){
		padding: 60px;
	}
	@include bp(md){
		padding: 40px 8% 0;
	}
	.list{
		margin-top: 80px;
		@include bp(md){
			margin-top: 40px;
		}
	}
	.single{
		margin-bottom: 45px; 
		padding: 0 40px;
		@include bp(xxl){
			padding: 0;
		}
		@include bp(md){
			margin-bottom: 15px;
		}
		.title{
			color: $darkblue;
			font-size: 1.15rem;
			font-weight: 700;
			margin: 0;
		}
		i{
			font-size: 38px;
			color: $primary;
			border: 1px solid #eceff7;
			width: 70px;
			height: 70px;
			display: flex;
			border-radius: 100px;
			justify-content: center;
			align-items: center;
			@include bp(sm){
				width: 55px;
				height: 55px;
				font-size: 30px;
			}
		}
		figcaption{
			padding: 0 20px;
		}
	}
}