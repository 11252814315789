/*////////////////////////////////////////////////////////////   HEADER   /////////////*/

@keyframes slidein {
  from {
    clip-path: inset(0 0 100% 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

.background_header{
	background: #0f2d87;
	display: grid;
	grid-template-columns: 8% 21% 21% 21% 21% 8%;
	grid-template-rows: 100%;
	max-height: 100%;
	position: relative;
	z-index: -1;
	overflow: hidden;
	@include bp(lg){
		grid-template-columns: 8% 42% 42% 8%;
	}
	@include bp(sm){
		grid-template-columns: 50% 50%;
	}
	.c{
		clip-path: inset(0 0 100% 0);
		animation-name: slidein;
		animation-timing-function: ease-in-out;
		animation-duration: 1s;
		transition: all .2s ease-out;
		height: 95vh;
		overflow: hidden;

		&:after{
			content: '';
			display: block;
			background: rgba(255,255,255,.2);
			height: 100%;
			width: 1px;
		}
		img{
			position: absolute;
			top: 0;
			left: 0;
			height:100%;
			z-index: -1;
			max-width: none;
		}
	}
	.showed{
		clip-path: inset(0 0 0 0);
	}
	.test2{	animation-delay: .3s; }
	.test3{	animation-delay: .6s; }
	.test4{	animation-delay: .9s; }
	.test5{	animation-delay: 1.2s; }
	.test6{	animation-delay: 1.5s; }
}
.homepage{
	height: 95vh;
	position: relative;
}


@keyframes fadeInUp {
  from {
    opacity: 0;
   	transform: translate(-50%,-0%);
  }

  to {
    opacity: 1;
  	transform: translate(-50%,-50%);
  }
}
@keyframes fadeInUpInner {
  from {
    opacity: 0;
   	transform: translate(-50%,0px);
  }

  to {
    opacity: 1;
  	transform: translate(-50%,-46px);
  }
}
@keyframes slideDown {
  from {
    height: 0;
  }

  to {
    height: 70px;
  }
}

.content_header{
	.title{
		position: absolute;
		top: 65%;
		left: 50%;
		transform: translate(-50%,-0%);
		color: white;
		text-align: center;
		animation-name: fadeInUp;
		animation-duration: 2s;
		animation-delay: 1.8s;
		transition: .2s ease-out;
		opacity: 0;
		max-width: 650px;
		@include bp(sm){
			top: 60%;
			width: 100%;
			padding: 0 35px;
		}
		&.showed{
			opacity: 1;
			transform: translate(-50%,-50%);
		}
		.single{
			font-size: 60px;
			font-weight: 600;
			@include bp(lg){
				font-size: 40px;
			}
			@include bp(sm){
				font-size: 30px;
			}
		}
		.special_title{
			font-size: 35px;
			font-family: Angelina;
			font-weight: 300;
			@include bp(md){
				font-size: 26px;
			}
			@include bp(sm){
				font-size: 22px;
			}
		}
		&:after{
			content: '';
			display: block;
			background: white;
			width: 2px;
			height: 0;
			margin: auto;
			animation-name: slideDown;
			animation-duration: 2s;
			animation-delay: 2s;
		}
		&.decoration{
			&:after{
				height: 70px;
			}
		}
	}
	&.inner{
		.title{
			transform: translate(-50%,0px);
			animation-name: fadeInUpInner;
			top: initial;
			bottom:0;
			&.showed{
				transform: translate(-50%,-43px);
			}
		}
	}
	.scrolldown{
		background: url(../images/scrolldown.svg) no-repeat;
		position: absolute;
		bottom: -1px;
		left: 50%;
		transform: translateX(-50%);
		width: 70px;
		background-size: cover;
		text-align: center;
		height: 23px;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		a{
			background: none;
			border: 0;
			line-height: 0;
		}
	}
}


/*////////////////////////////////////////////////////////////   SERVICE   /////////////*/
.service{
	padding: 115px 8%;
	position: relative;
	@include bp(sm){
		padding: 70px 8% 50px;
	}
	&:after{
		content: '';
		background: white;
		background: linear-gradient(180deg, rgba(240,246,250,1) 0%, rgba(255,255,255,0) 100%); 
		width: 100%;
		position: absolute;
		z-index: -2;
		height: 55%;
		top: 40%;
		left: 0;
		@include bp(lg){
			top: 25%;
		}
		@include bp(md){
			top: 15%;
		}
	}
	figure{
		border-radius: 5px;
		overflow: hidden;
		transition: all .4s ease-out;
		img{
			width: 100%;
		}
	}
	article{
		text-align: center;
		@include bp(lg){
			margin-bottom: 30px;
		}
		&:hover{
			figure{
				transform: scale(1.05);
			}
		}
	}
	.content{
		padding: 0 30px;
		@include bp(xl){
			padding: 0 15px;
		}
		a{
			text-transform: uppercase;
			font-weight: 800;
			letter-spacing: 1px;
			font-size: 12px;
			&:after{
				content: '>';
				color: $darkblue;
				width: 20px;
				height: 20px;
				border-radius: 50px;
				border: 1px solid #b1bde0;
				display: inline-block;
				font-weight: 400;
				font-size: 14px;
				margin-left: 15px;
				line-height: 1;
				padding: 3px 1px;
			}
		}
	}
	.title{
		color: $darkblue;
		font-weight: 700;
		font-size: 20px;
		&:after{
			content: '';
			display: block;
			background: $darkblue;
			width: 30px;
			height: 1px;
			margin: 10px auto 15px;
		}
	}
	
}

/*////////////////////////////////////////////////////////////   A PROPOS   /////////////*/
.apropos{
	background: url(../images/background_about_home.png) bottom center repeat-x, $lightblue;
	color: $textOn-lightblue;
	padding: 80px 15px;
	margin-top: 180px;
	@include bp(lg){
		margin-top: 140px;
	}
	@include bp(md){
		margin-top: 80px;
	}
	@include bp(sm){
		margin-top: 0px;
		padding: 50px 15px;
	}
}
.aside-left{
	padding: 0;
	position: relative;
	img{
		margin-top: -30%;
	}
}
.aside-right{
	padding-right: 8%;
	@include bp(lg){
		padding: 50px 8% 0;
	}
}
