/*/////////////////////////////////////////////////   gallery grid   /////////////*/
.galerie{
	column-count: 3;
	column-gap: 80px;
	padding: 80px 8% 0;
	column-fill: auto;
	min-height: 20em;
	@include bp(xl){
		column-gap: 40px;
	}
	@include bp(lg){
		column-count: 2;
	}
	@include bp(sm){
		column-count: 1;
	}
	.single{
		margin-bottom: 50px;
		break-inside:avoid;
		text-align: center;
		cursor: pointer;
		figure{
			overflow: hidden;
			border-radius: 5px;
			img{
				min-width: 100%;
				transition: all .2s ease;
			}
			&:hover{
				img{
					transform: scale(1.03);
				}
			}
		}
		.title{
			color: $darkblue;
			font-size: 1.2rem;
			font-weight: 700;
			margin: 25px 0 0;
			&:after{
				content: '';
				display: block;
				width: 20px;
				height: 1px;
				background: $darkblue;
				margin: 10px auto;
			}
		}
	}
}

/*/////////////////////////////////////////////////   tri   /////////////*/
.tri{
	text-align: center;
	margin-top: -13px;
	span{
		background: white;
		display: inline-block;
		margin: auto;
		padding: 0 45px;
	}
	ul{
		flex-wrap: wrap;
	}
}
.tri-btn{
	border-radius: 5px;
	margin: 35px 15px 0;
	border: 1px solid #b1bde0;
}

/*/////////////////////////////////////////////////   pagination   /////////////*/

.pagination-section{
	padding: 40px 0;
	display: flex;
    align-items: center;
    justify-content: center;
	button{
		background: none;
		border: 0;
		color: $font-text-color;
		&:hover{
			color: $darkblue;
		}
	}
	ul{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
	}
	li{
		margin: 0 5px;
		font-size: 1.1rem;
	}
	.prev, .next{
		margin: 0 5px;
		font-size: 1.1rem;
		border: 1px solid #b1bde0;
		border-radius: 50px;
		width: 25px;
		height: 25px;
		padding:0;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 0.9rem;
		transition: all .2s ease;
		&:hover{
			background: $lightblue;
		}
		button{
			padding: 0;
			line-height: 0;
			color: $darkblue;
			&:focus{
				outline: 0;
			}
		}
	}
	.active button{
		color: $primary;
		font-weight: 600;
		font-size: 1.2rem;
	}
}