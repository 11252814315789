/*/////////////////////////////////////////////////   engagement qualité   /////////////*/
.floating-label{
    position: relative;
    margin-bottom: 40px;
    @include bp(md){
    	margin-bottom: 25px;
    }
    @include bp(sm){
    	margin-bottom: 15px;
    }
    label{
        position: absolute;
        top: calc(50% - 7px);
        opacity: 0;
        font-size: 14px;
        letter-spacing: 0px;
        background: rgba(255,255,255,0);
        transition: all .6s ease, background .6s ease .2s;
        padding: 0 10px;
        display: block;
        left:5px;
    }
    input:not(:placeholder-shown) + label{
        transform: translateY(-25px);
        opacity: 1;
        background: rgba(255,255,255,1);
    }
    textarea:not(:placeholder-shown) + label{
        transform: translateY(-46px);
        opacity: 1; 
        background: rgba(255,255,255,1);
    }
}
.form-control{
	&:focus{
		box-shadow: none;
	}
}

/*/////////////////////////////////////////////////   coordonnes   /////////////*/

.coordonnees{
	li{
		position: relative;
		padding: 0 0 0 40px;
		margin-bottom: 30px;
		font-size: 20px;
		color: $textOn-lightblue;
		@include bp(md){
			font-size: 18px;
		}
		@include bp(sm){
			font-size: 15px;
			margin-bottom: 20px;
		}
		a{
			color: $textOn-lightblue;
			text-decoration: underline;
		}
		&:after{
			content:'';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			font-family: icomoon;
			font-size: 22px;
			color: $primary;
		}
	}
	.tel{
		&:after{
			content: $icon-phone;
		}
	}
	.mail{
		&:after{
			content: $icon-mail;
		}
	}
	.adresse{
		&:after{
			content: $icon-map-pin;
		}
	}
}

/*/////////////////////////////////////////////////   form   /////////////*/

.contact{
	.row{
		margin:0;
	}
	.domaine{
		@include bp(sm){
			padding: 40px 15px 30px;
		}
		button{
			margin-top: 40px;
		}
	}
	.text{
		.floating-label{
			margin-bottom: 15px;
		}
	}
}

.map{
	#map{
		@include bp(md){
	    	height: 300px;
	    }
	    @include bp(sm){
	    	height: 300px;
	    }
		width: 100%;
		height: 500px;
	}
}